<template>
	<div class="layout--full-page">
		<router-view></router-view>
	</div>
</template>

<script>
import authApi from '@/api/auth';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    fetchUser() {
      const TOKEN_KEY = 'access_token';
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token || token === undefined) return;
      const localUserData = JSON.parse(localStorage.getItem('userInfo'));
      const callback = (response) => {
        const user = response.data;
        if (user) {
          if (localUserData) {
            user.name = localUserData.name === 'Anonymous' ? user.name : localUserData.name;
          }
          this.$store.dispatch('setUser', user);
        }
      };
      const errCallback = (err) => {
        console.error(err);
      };
      authApi.getProfile(callback, errCallback);
    },
  },
  created() {
    // Check if event live page
    if (this.currentRouteName === 'EventLive') return;

    // Fetch User Details
    this.fetchUser();
  },
  beforeDestroy() {
  },
};
</script>
